<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="partner.executor_sole_primary"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/executors/type_of'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'ExecutorsPartner',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    isValidated() {
      return true
    },
    number() {
      return '2'
    },
    heading() {
      return (
        'Would you like to name ' +
        this.partner.full_name +
        ' as your main executor?'
      )
    },
    subHeading() {
      return (
        "It is common to have your partner or spouse named as the main executor.  You can then name 'secondary executors' who will step in " +
        'should ' +
        this.partner.full_name +
        ' pass away before you.'
      )
    },
    forwardTo() {
      if (this.partner.executor_sole_primary) return '/executors/secondary'
      return '/executors/primary'
    }
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        this.person = value
      }
    }
  },
  data() {
    return {
      loading: false,
      person: null
    }
  },
  methods: {
    save() {
      if (this.person) {
        var copyPerson = Object.assign({}, this.person)
        copyPerson.executor_sole_primary = !this.person.executor_sole_primary
        this.$store.commit('personAdd', copyPerson)
      }
      this.resetExecutors()
    },
    resetExecutors() {
      this.people.forEach((person) => {
        let personCopy = Object.assign({}, person)
        personCopy.executor_primary = false
        personCopy.executor_secondary = false
        personCopy.executor_backup = false
        this.$store.commit('personAdd', personCopy)
      })
      this.$store.commit('details', {
        executors_backup_required: false,
        executors_secondary_required: false
      })
    }
  }
}
</script>

<style></style>
